.inlineHover > i {
    margin-right: 5px;
    margin-left: 2px;
    font-size: 15px;
    cursor: pointer;
}
.inlineHover.input-select2 > i.icon.ion-checkmark-round,
.inlineHover.input-select2 > i.fa.fa-times-circle-o {
    position: relative;
    top: 8px;
}
.inlineHover > i.icon.ion-edit,
.inlineHover > i.icon.ion-android-delete {
    visibility: hidden;
}
.inlineHover > .icon.ion-checkmark-round {
    margin-left: 5px;
}

.inlineHover:hover > i.icon.ion-edit,
.inlineHover:hover > i.icon.ion-android-delete {
    visibility: visible;
    cursor: pointer;
}

.inlineHover > i.icon.ion-edit {
    font-size: 14px;
}
.inlineHover > i.icon.ion-checkmark-round {
    color: #44c699;
}

.inlineHover > i.icon.ion-edit {
    color: #d3d3d3;
}

.inlineHover > i.icon.ion-android-delete {
    color: #d3d3d3;
}

.inlineHover > i.fa.fa-times-circle-o {
    color: #d3d3d3;
}

.inlineHover .tags{
    word-break: normal;
    display: block;
}

.inlineHover .tags li{
    display: inline-block;
    margin-bottom: 5px;
}

.inlineHover>input {
    padding-right: 0px !important;
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid #44c699;
    padding-left: 0px !important;
    margin-right: 10px;
    letter-spacing: 0;
}

.inlineHover>input:focus {
    border: none;
    border-bottom: 1px solid #44c699;
    box-shadow: none;
}
.inlineHover>input[type=date] {
    line-height: 16px;
    border: none;
    cursor: pointer;
}

.inlineHover>[type=date] {
    background:#fff;
}

.inlineHover>select {
    border-radius: 6px;
    background: transparent;
    width: auto;
    height: auto;
    -webkit-appearance: menulist-button;
    width: 80%;
}
.inlineHover>[type=date]::-webkit-inner-spin-button {
    display: none;
    -webkit-appearance: none;
}
.inlineHover>[type=date]::-webkit-clear-button
{
    color: #a0a0a0;
    font-size: 12px;
}
.inlineHover>[type=date]::-webkit-calendar-picker-indicator {
    color: #a0a0a0;
    font-size: 14px;
}

.inlineHover .icon-before-input {
    margin-right: 7px;
}

.inlineHover .icon-before-input i{
    color: #a0a0a0;
}

.inlineHover .button.green {
    font-size: 14px;
    margin-left: 10px;
}
#comments {
    padding: 10px !important;
}

#comments input {
    height: 40px;
}

#comments input[type='text'] {
    width: 80%;
    min-width: 190px!important;
    border: 1px solid #ccc;
    border-right: none;
    border-radius: 5px 0 0 5px;
}

#comments input[type='button'] {
    width: 20%;
    border: 1px solid #ccc;
    border-left: none;
    border-radius: 0 5px 5px 0;
    background-color: #44c699;
    color: #fff;
}

#comments .single-message {
    margin-top: 30px;
}

#comments .image-container {
    padding: 0;
    text-align: right;
}

#comments .single-message img {
    width: 30px;
    border-radius: 50%;
    height: 30px;
}

.content-container {
    padding-left: 0;
}

#comments .content-container h3 {
    text-align: center;
}


#comments .content-container .user-comments:nth-child(2) .comment-content {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    word-wrap: break-word;
}

#comments .content-container .user-comments:last-child .comment-content {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    word-wrap: break-word;
}

#comments .single-message .author {
    font-weight: bold;
    text-align: center;
}

#comments .single-message .publish-date {
    font-weight: bold;
    text-align: right;
    color: #ccc;
    width: 100%;
    padding-right: 15px;
}

#comments .single-message .comment-content {
    padding: 10px;
    background: #fbfafb;
    border: 1px solid #e3e3e3;
    color: black;
    margin-left: 10px;
    margin-top: 5px;
    cursor: pointer;
    word-wrap: break-word;
}

#comments .single-message .comment-content .comment-actions {
    text-align: right;
}

#comments .comment-actions a {
    color: inherit;
    cursor: pointer;
    text-decoration: none;
}

.modalConfirms {
    background-color: #fff;
    border-radius: 5px;
    width: 40%;
    height: auto;
    min-height: 100px;
    margin: 0 auto;
}

.modalConfirms .panel-body {
    width: 90%;
}

.modalConfirms .panel {
    border: 0;
    box-shadow: none;
}

.modalConfirms header {
    display: none!important;
}

.line-address>i.icon {
    visibility: hidden;
    margin-right: 8px;
}

.line-address:hover > i.icon {
    visibility: visible;
    font-size: 15px;
    cursor: pointer;
}
.line-address {
    justify-content: right!important;
    font-size: 16px;
    margin-left: 5px;
    margin-bottom: 12px;
    margin-right: 25px;
}
.line-note {
    justify-content: right!important;
    font-size: 14px;
    margin-left: 5px;
    margin-bottom: 12px;
    margin-right: 25px;
}
.line input {
    min-width: 40px!important;
}
.btn-crm {
    float: none;
}

.invoice-profile-other-information textarea {
    background: rgb(255, 255, 255);
    border: 1px solid rgb(202, 202, 201);
    border-radius: 3px;
    box-shadow: none;
    margin-top: 0px;
    margin-bottom: 0px;
    height: 140px;
}

.add-contact {
    font-weight: bold!important;
}

.add-contact>i {
    margin-right: 7px;
}

.add-contact:hover,  .add-contact:active, .add-contact:focus{
    text-decoration: none;
    color: #110872;
}

.infoMessage{
   margin: 20px 0px;
   padding-left: 0px;
   font-size: 16px;
}

.invoice-profile .account-profile .account-profile-information .account-profile-other-information i{
    top: -23px;
}

.invoice-profile .account-profile .account-profile-information .account-profile-other-information .account-profile-other-information-more{
    padding-bottom: 20px;
}

.invoice-profile .account-profile .account-profile-information .account-profile-other-information .account-profile-other-information-more .btn-add-payment{
    position: relative;
    top: -50px;
    left: 400px;
}

.invoice-profile .account-profile .account-profile-information .account-profile-other-information .account-profile-other-information-more .btn-add-payment i{
    color: white;
}

.invoice-profile .account-profile .account-profile-information .account-profile-other-information .account-profile-other-information-more .btn-add-payment .btn-round{
    position: absolute;
    width: 20px;
    height: 20px;
    font-size: 15px;
    line-height: 23px;
    right: 250px;
    top: 20px;
}

.invoice-profile .account-profile .account-profile-information .account-profile-other-information .account-profile-other-information-more .btn-add-payment .dropdown-menu{
    top: 40px;
    left: 100px;
}

.invoice-profile .account-profile .line .title{
    font-weight: bold;
}

.invoice-profile .account-profile .line a.link{
    margin-left: 6px;
    font-size: 20px;
}

.invoice-profile .account-profile .line .payment-status span.unpaid{
    color: #ec3600;
}
.invoice-profile .account-profile .line .payment-status span.paid{
    color: #208052;
}
.invoice-profile .account-profile .line .payment-status span.partialypaid{
    color: #4d378f;
}
.invoice-profile .account-profile .line .invoice-no{
    margin-bottom: 10px;
}

.invoice-profile .account-profile .line .amount span,
.invoice-profile .account-profile .line .due span
{
    color: #716f6f;
    font-weight: bold;
}

.invoice-profile .account-profile .line span {
    color: #464646;
}
.invoice-profile .account-profile .actions{
    margin-bottom: 10px;
}

.invoice-profile .account-profile .actions .template-options span{
    text-transform: uppercase;
    margin-right: 10px;
}
.invoice-profile .account-profile .actions span{
    margin-right: 40px;
}

.invoice-profile .account-profile .actions span i{
    margin-left: 7px;
}

.invoice-profile .account-profile .actions span a{
    margin-left: 6px;
    color: #4eb0e2;
    text-decoration: none;
    text-transform: uppercase;
}

.invoice-profile .account-profile .line .dueDate{
  border: 1px solid #ffdb9a;
  margin-left: 15px;
  padding: 6px;
  font-size: 12px;
  border-radius: 4px;
}

.invoice-profile .account-profile .account-profile-information .account-profile-other-information .payment-form i{
    top:25px;
}

.invoice-profile .account-profile .account-profile-information .account-profile-other-information .account-profile-other-information-more .btn-add-payment .btn-round button{
    background: transparent;
    border: none;
    right: 10px;
    top: -4px;
    font-size: 15px;
}

.general.item-profile-left .content span {
    color: inherit;
}
.item-profile-left .item-title{
    font-size: 26px;
    margin-top: 20px;
}
.item-profile-left .item-picture{
    height: 70px!important;
    margin: 10px 0;
    border: 1px solid lightgray;
    border-style: dotted;
    border-radius: 2px;
    display: flex;
    align-items: center;
}
.item-profile-left .item-picture img{
    width: 100%;
    max-width: 70px!important;
    max-height: 70px!important;
    padding: 5px;
}
.item-profile .account-profile .line .title .help{
    left: 0px;
}
.itemHistory-table{
    border: 1px solid lightgray;
    background: white;
    border-radius: 2px;
    box-shadow: 0 0 7px 0 #d4d4d4;
}
.ant-form-inline .ant-form-item {
    float: right;
}
.ant-table-title {
    margin-left: 16px;
}
.ant-table-pagination.ant-pagination {
    padding-right: 16px;
}
.item-profile .itemHistory-table .ant-table {
      color: inherit;
      overflow: auto;
}
.item-profile .account-profile .account-profile-tabs .nav.nav-tabs div {
    margin-top: 0px;
}
.itemHistory-table.ant-table-row-cell-break-word {
    word-break: inherit;
}
.item-profile .ant-table-row-cell-break-word {
    word-break: inherit;
}
.item-profile .account-profile .account-profile-tabs {
    border: none;
    background: transparent;
    margin-top: 10px;
    padding: 0;
    box-shadow: none;
    border-bottom: transparent;
}
.item-profile .account-profile .account-profile-tabs .nav.nav-tabs {
    background: none;
}
.item-profile .account-profile .account-profile-tabs .btn-tabs{
  border-bottom: transparent;
}
.item-profile .account-profile .account-profile-tabs .btn-tabs.active {
    border-bottom: 2px solid #4eb0e2!important;
}
.ion-eye {
    cursor: pointer;
}
.itemStatus-active{
    margin-right: 10px;
    background-color: #ccffcc;
    position: relative;
    border-radius: 15px;
    text-align: center;
    color: #30a15d;
    padding: 1px 10px;
    font-weight: bold;
}
.itemStatus-disable{
    margin-right: 10px;
    background-color: #ffdcd2;
    position: relative;
    border-radius: 15px;
    text-align: center;
    color: #ea2c2b;
    padding: 1px 10px;
    font-weight: bold;
  }
/* SalesProfile*/
.document-template{
  position: absolute;
  right: 25px;
  top: -49px;
  display: flex;
  align-items: center;
}
.onlineOrder-status{
    border: 1px solid #dce0e8;
    border-radius: 6px;
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.05);
    background: white;
    text-align: center;
    margin-top: 10px;
}
.onlineOrder-status .container-status{
  padding: 20px 0;
}
.onlineOrder-status .payment-bg{
    background-image: linear-gradient(-180deg,#fff 0%,#fff1dc 150%);
    border-radius: 6px;
}
.onlineOrder-status .delivery-bg{
    background-image: linear-gradient(-180deg,#fff 0%,#e8fbff 150%);
    border-radius: 6px;
}
.onlineOrder-right-side .ant-card{
    border: 1px solid #dce0e8;
    margin-top: 10px;
    border-radius: 6px;
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.05);
    background: white;
  }

  @media only screen and (max-width: 780px) {
    .sale-tabs {
        display: grid;
    }

    .sale-tabs .btn-tabs{
        margin-bottom: 0px !important;
        margin-top: 0px !important;
    }

    .sale-tabs .btn-tabs.active{
        border: 2px solid #4eb0e2!important;
    }
  }
  @media only screen and (max-width: 900px) {
    .item-profile .account-profile {
        padding-left: 10px;
        padding-right: 10px!important;
    }
    .item-profile-right .actions{
        margin: 10px 0px;
    }
    .item-profile-right .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
        padding: 16px 4px!important;
    }
    .item-profile .account-profile .account-profile-tabs .btn-tabs{
        font-size: 14px;
        margin-left: 0px;
        padding: 5px;
        margin-top: 15px;
        margin-bottom: 15px;
      }
      .document-template {
        position: initial;
      }
  }


  .single-chart {
    width: 98%;
    justify-content: space-around ;
  }
  
  .circular-chart {
    display: block;
    margin: 10px auto;
    max-width: 80%;
    max-height: 150px;
  }
  
  .circle-bg {
    fill: none;
    stroke: #eee;
    stroke-width: 3.8;
  }
  
  .circle {
    fill: none;
    stroke-width: 2.8;
    stroke-linecap: round;
    animation: progress 1s ease-out forwards;
  }
  
  @keyframes progress {
    0% {
      stroke-dasharray: 0 100;
    }
  }
  
  .circular-chart.orange .circle {
    stroke: #ff9f00;
  }
  
  .circular-chart.green .circle {
    stroke: #4CC790;
  }
  
  .circular-chart.blue .circle {
    stroke: #3c9ee5;
  }
  
  .percentage {
    fill: #666;
    font-family: sans-serif;
    font-size: 0.5em;
    text-anchor: middle;
  }  
 
  /* DealProfile*/
.template-select{
    margin: 0 20px 20px 0;
  }
  
/* Projects Profile*/
.profile-description-rte{
    padding: 5px;
    height: 100%;
    all: initial;
}

.text-line-through {
  text-decoration: line-through;
}

/*.projectsAndTasksTable .ant-table-row-level-0{
    background-color: #f1f1f1db;
}*/
.projectsAndTasksTable .ant-table-row-level-1{
    background-color: #e7eef5b8;
}
.projectsAndTasksTable .ant-table-row-level-2{
    background-color: #f4f5e7b8;
} 

.projectsAndTasksTable .ant-table-row-level-2 td:first-of-type{
    padding-left: 0;
    padding-right: 0;
}

.projectsAndTasksImagePreview .upload-list-inline .ant-upload-list-item {
    float: left;
    width: 250px;
    margin-right: 8px;
    height: 118px;
}

.projectsAndTasksImagePreview .upload-list-inline .ant-upload-list-item-thumbnail{
    width: 180px;
    height: 98px;
}

.projectsAndTasksImagePreview .upload-list-inline .ant-upload-list-item-image{
    width: 208px;
    height: 98px;
}

.projectsAndTasksImagePreview .upload-list-inline .ant-upload-list-item-name{
    padding-left: 98px;
    line-height: 98px;
    display: none;
}

.projectsAndTasksImagePreview .upload-list-inline .ant-upload-list-item-card-actions.picture{
    top: 50px;
}

.projectsAndTasksImagePreview .ant-upload-list-picture{
    background: white;
    margin-top: 15px;
    padding: 10px;
}

.ant-table.ant-table-bordered > 
.ant-table-container > 
.ant-table-content > table > thead > tr > th, .ant-table.ant-table-bordered > 
.ant-table-container > .ant-table-header > table > thead > tr > th, 
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > th, 
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > thead > tr > th, 
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td, 
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td, 
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td, 
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tbody > tr > td, 
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > th, 
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > th, 
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > th, 
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tfoot > tr > th, 
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > td, 
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > td, 
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > td{
    border-right: 0px !important;
}

.invoice-profile .document-profile-tabs {
    display: -webkit-flex;
    -webkit-justify-content: flex-end;
}

@media only screen and (min-width: 1200px) {
    .email-profile-message{
        height: 100%;
    }

}
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@400;600&display=swap');

*,
*::before,
*::after {
  box-sizing: border-box;
}

/* page styling */
.pg-content {
  position: relative;
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
  font-family: 'Fira Sans', Arial, Helvetica, sans-serif;
  background-color: #f2f6ff;
}

/* for print */
.invoice-title {
  width: 48%;
  padding: 0;
  text-align: center;
}

.printData {
  background-color: white;
  margin: auto;
  border-radius: 2px;
  padding: 40px;
}

/* Header */
.navbar.pg-header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 50;
  width: 100%;
  min-height: 50px;
  background-color: #fff;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
}
.bar-colors {
  position: absolute;
  bottom: -3px;
  width: 100%;
  height: 3px;
  background-image: linear-gradient(to right, #fc5c7d, #6a82fb);
}
.logo-wrapper {
  display: inline-block;
  margin: 5px;
}
.logo-wrapper img {
  display: inline-block;
  width: auto;
  height: 100%;
}
.navbar-right {
  display: flex;
  align-items: center;
  margin-right: 125px;
  margin-top: 12px;
}
.navbar-right .icon-wrapper {
  display: flex;
  margin-right: 30px;
}
.navbar-right .language {
  display: flex;
  align-items: center;
}
.navbar-right .language span {
  color: black;
}
.navbar-right .language:hover {
  text-decoration: none;
}
.navbar-right .language .icon-wrapper {
  display: flex;
  margin-right: 5px;
}

.container.pg-container {
  padding: 80px 0 30px;
  width: 100%;
  max-width: 1480px;
  min-height: 100vh;
  margin: 0 auto;
}

/* Breadcrumbs */
.breadcrumb.pg-breadcrumbs {
  width: 100%;
  margin: 0 0 15px;
  padding: 0;
  background-color: transparent;
  /* is in use */
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.pg-breadcrumbs > li {
  font-size: 16px;
}
.pg-breadcrumbs .anticon-right {
  margin: 0 5px;
  color: #777;
  font-size: 12px;
}
.pg-breadcrumbs .anticon-right svg {
  width: 10px;
  height: 10px;
}
.pg-breadcrumbs .breadcrumb-item a {
  color: #212529;
}
.pg-breadcrumbs .breadcrumb-item.active {
  color: black;
}

/* Card */
.card.pg-card {
  position: relative;
  z-index: 2;
  margin: 0 0 20px;
  padding: 30px;
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.card .card-price {
  margin: 0 0 15px;
  padding: 10px;
  border-radius: 6px;
  /*background-color: #f5f5f5;*/
  background-color: #f2f6ff;
}
.card .lead {
  font-size: 16px;
  font-weight: 600;
}
.card .card-price .total {
  display: flex;
  align-items: baseline;
  gap: 6px;
  font-size: 24px;
}
.card .card-price .total .curency,
.card .card-price .total .curency-sign {
  font-size: 18px;
}

.card .card-price--info {
  display: flex;
  gap: 6px;
  background: #fff;
}
.card .card-price--info .lead {
  margin-bottom: 0;
}
.card .card-price--info .status,
.card .card-price--info .due-date {
  width: 50%;
}
.card .payment-history {
  display: flex;
}
.card .payment-history .date {
  width: 65%;
  font-size: 16px;
  font-weight: bold;
}
.card .payment-history .payment-info .curency {
  font-size: 12px;
}

/* Buttons */
.buttons {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-top: 40px;
  z-index: 20;
}
.btn-download {
  padding: 10px 30px;
  font-size: 24px;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 0.04em;
  border-radius: 10px;
  background-color: #5bba6f;
  color: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  transition: all 300ms ease-in-out;
}
.btn-download:hover,
.btn-download:focus,
.btn-download:active {
  background-color: #3fa34d;
  color: #fff;
  transform: translateY(2px);
}
.btn-pay {
  padding: 10px 30px;
  font-size: 24px;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 0.04em;
  border-radius: 30px;
  background-color: #5bba6f;
  color: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  transition: all 300ms ease-in-out;
}
.btn-pay:hover,
.btn-pay:focus,
.btn-pay:active {
  background-color: #3fa34d;
  color: #fff;
  transform: translateY(2px);
}

/* issuer and decorations */
.software {
  position: relative;
  z-index: 20;
  display: inline-block;
  width: 100%;
  margin: 50px auto 0;
  font-size: 16px;
  text-align: center;
}
.software span {
  display: inline-block;
  padding: 5px;
  background-color: #f2f6ff;
}
.bg-icon-wrapper-left,
.bg-icon-wrapper-right {
  position: absolute;
  z-index: 0;
}
.bg-icon-wrapper-left > img,
.bg-icon-wrapper-right > img {
  display: inline-block;
  width: 100%;
  height: auto;
}
.bg-icon-wrapper-left {
  width: 300px;
  bottom: 0;
  left: 0;
}
.bg-icon-wrapper-right.fix {
  top: 80px;
  right: 0;
}

#printDoc.ant-menu-item.ant-menu-item-selected.ant-menu-item-only-child {
  background-color: #323639 !important;
}
.mobile-text {
  display: none;
  max-width: 100%;
  padding: 30px 0 40px;
  font-size: 20px;
  line-height: 1.2;
  text-align: center;
}

/* Responsive adjust */
@media print {
  body {
    -webkit-print-color-adjust: exact;
  }
}

@media (max-width: 769px) {
  .container.pg-container {
    padding-top: 70px;
  }
  .breadcrumb {
    font-size: 14px;
  }
  .mobile-embed {
    display: none;
    max-width: 100%;
    padding: 0px 20px;
  }
  .mobile-text {
    display: block;
  }
  .card.pg-card {
    width: 100%;
    padding: 20px 15px;
  }
  .mobile-skeleton {
    display: none;
  }
  .card-price--info {
    padding-left: 10px;
  }
  .buttons {
    justify-content: center;
  }
  .software {
    width: 100%;
    margin-top: 30px;
  }
  .bg-icon-wrapper-left {
    width: 200px;
  }
  .bg-icon-wrapper-right.fix {
    top: 240px;
  }
}
@media (max-width: 992px) {
  .mobile-embed {
    margin-bottom: 20px;
  }
}

/* OLD Styles not in use */
/*
.invoice-title {
  width: 48%;
  padding: 0;
  text-align: center;
}

.printData {
  background-color: white;
  margin: auto;
  border-radius: 2px;
  padding: 40px;
}
.navbar {
  background-color: white;
  position: relative;
}
.bar-colors {
  position: absolute;
  bottom: -3px;
  width: 100%;
  height: 3px;
  background-image: linear-gradient(to right, #FC5C7D, #6A82FB);
}
.logo-wrapper img {
  display: inline-block;
  max-height: 100%;
  margin: 5px;
}
.navbar-right {
  display: flex;
  align-items: center;
  margin-right: 125px;
  margin-top: 12px;
}
.navbar-right .icon-wrapper {
  display: flex;
  margin-right: 30px;
}
.navbar-right .language {
  display: flex;
  align-items: center;
}
.navbar-right .language span {
  color: black;
}
.navbar-right .language:hover {
  text-decoration: none;
}
.navbar-right .language .icon-wrapper {
  display: flex;
  margin-right: 5px;
}
.container {
  width: 1420px;
  max-width: 1480px;
  padding-top: 50px;
  padding-bottom: 30px;
  padding-left: 0;
  min-height: calc(100vh - 50px);
  font-family: 'Fira Sans', sans-serif;
}
.container .breadcrumb {
  background-color: transparent;
  padding-left: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  max-width: 100vw;
}
.container .breadcrumb-item a {
  color: #212529;
  font-size: 16px;
}
.container .breadcrumb-item.active {
  color: black;
}

.breadcrumb > li + li:before {
  padding: 0 3px;
}
.container .breadcrumb > li {
  font-size: 16px;
}

.card {
  border-radius: 16px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin-bottom: 20px;
  position: relative;
  z-index: 2;
}
.card-body {
  padding: 30px;
  background-color: white;
  border-radius: 24px;
}
.card .card-price {
  /*background-color: #f5f5f5;*/ /*
  background-color: rgb(242, 246, 255);
  padding: 10px;
  border-radius: 6px;
  margin-bottom: 15px;
}

.card .lead {
  font-size: 16px;
  font-weight: bold;
}
.card .card-price .total {
  font-size: 22px;
  display: flex;
  align-items: baseline;
}
.card .card-price .total .curency {
  font-size: 17px;
  margin-left: 5px;
}

.card .card-price .total .curency-sign {
  margin-left: 5px;
}

.card .card-price--info {
  background-color: white;
  display: flex;
}
.card .card-price--info .lead {
  margin-bottom: 0;
}
.card .card-price--info .status,
.card .card-price--info .due-date {
  width: 50%;
}
.card .payment-history {
  display: flex;
}
.card .payment-history .date {
  width: 65%;
  font-size: 16px;
  font-weight: bold;
}
.card .payment-history .payment-info .curency {
  font-size: 12px;
}
.buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}
.btn-download {
  padding: 5px 30px;
  font-size: 24px;
  background-color: #5bba6f;
  color: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  /* width: 40%; */ /*
  font-weight: bold;
}
.btn-download:hover {
  color: #fff;
  background-color: #3fa34d;
}
.btn-pay:hover {
  background-color: white;
  color: #44c699;
}
.btn-pay {
  padding: 5px 67px;
  font-size: 24px;
  color: white;
  background-color: #44c699;
  border-radius: 30px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  /* width: 40%; */ /*
  font-weight: bold;
}
.software {
  margin-top: 55px;
  font-size: 16px;
  text-align: center;
}
.bg-icon-wrapper-left {
  position: absolute;
  bottom: 0;
  left: -300px;
  width: 300px;
}
.bg-icon-wrapper-left img {
  width: 100%;
  height: auto;
}
.bg-icon-wrapper-right.fix {
  position: absolute;
  top: 80px;
  right: 0;
  z-index: 0;
}
@media print {
  body {
    -webkit-print-color-adjust: exact;
  }
}

#printDoc.ant-menu-item.ant-menu-item-selected.ant-menu-item-only-child {
  background-color: #323639 !important;
}

.mobile-text {
  max-width: 100vw;
  display: none;
  padding: 20px 30px 20px 20px;
  text-align: center;
  font-size: 20px;
}
@media (max-width: 768px) {
  .container {
    padding-top:20px;
  }
  .mobile-embed {
    padding: 0px 20px;
    max-width: 100vw;
    display: none;
  }

  .breadcrumb {
    font-size:14px;
  }

  .mobile-text {
    display: block;
  }
  .card-body {
    padding: 30px 15px;
  }
  .card {
    width: 96%;
  }
  .mobile-container {
    padding: 0px 20px;
    max-width: 100vw;
    position: relative;
    z-index: 10;
  }
  .justify-content-center {
    width: 100vw;
    z-index: 10;
    position: absolute;
    bottom: 50px;
  }

  .mobile-skeleton {
    display: none;
  }
  .card-price--info {
    padding-left: 10px;
  }
  .bg-icon-wrapper-right.fix {
    top: 260px;
  }
}
*/

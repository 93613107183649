/*
    Created on : Mar 10, 2020, 10:25:14 PM
    Author     : veronika
*/

/*CUSTOM DATA*/
.single-block{
   padding: 10px 0px;
   border-bottom: 1px solid #e8e8eb;
   cursor: pointer;
}
.block-header span{
   font-size: 18px;
   font-weight: 600;
}

.slide-modal.backendropss {
    padding: 0;
}

.slide-modal .modalConfirms {
    float: right;
    width: 80%;
    height: 100%;
    right: 0;
    border-radius: 0;
    animation: slide 0.6s linear alternate;
    overflow-y: auto; 
}

.gridModal {
    height: 45em;
}

@keyframes slide {
    0% { width: 0; }
    10% { width: 10%; }
    20% { width: 20%; }
    30% { width: 30%; }
    40% { width: 40%; }
    50% { width: 50%; }
    55% { width: 55%; }
    60% { width: 60%; }
    65% { width: 65%; }
    70% { width: 70%; }
}

.settings_message {
    position: fixed;
    bottom: -20px;
    width: 85%;
    height: 60px;
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}
.settings_message > p {
    background: #44c699;
    margin: 0 auto;
    text-align: center;
    height: 40px;
    font-size: 18px;
    line-height: 2;
    color: white;
    border-radius: 10px 10px 0px 0px;
}

.settings_message > p.error {
    background: #e50000;
}
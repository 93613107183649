.tableCollection table {
    width: 100%;
}

.tableCollection {
    display: block;
    position: relative;
}

.tableCollection table > thead {
    border: 1px solid #ccc;
    background-color: #eaf0f5;
}

.tableCollection table > thead td {
    border: 0;
    padding: 10px 3px 0 3px !important;
    font-weight: bold;
}

.tableCollection table tbody td{
    border: 1px solid #ccc;
}

.tableCollection table tbody td.rowNumber{
    background-color: #eaf0f5;
    padding: 10px !important;
    text-align: center;
}

.tableCollection table td.left, .tableCollection table td.right {
    background-color: transparent !important;
    border: 1px solid transparent !important;
    width: 10px;
    text-align: center;
}

.tableCollection table td.left span i, .tableCollection table td.right span i {
        color: #8c9cb0;
        font-size: 16px;
}
.tableCollection table td.right span i:hover {
     color: red;
}
.tableCollection table td.right {
    border-left: 1px solid #ccc !important;
    padding-left: 10px;
}

.tableCollection table td.left {
    border-right: 1px solid #ccc !important;
    padding-right: 10px;
}

.tableCollection table td.tableHeader {
    background-color: #f0eae7 !important;
}

.tableCollection.stages table td.tableHeader {
    background-color: #ffffff !important;
}

.tableCollection table td i {
    cursor: pointer;
}

.tableCollection input[type='text'], .tableCollection input[type='number'] {
    margin: 0 !important;
    border-radius: 0;
    background-color: #fff;
}
.tableCollection input[type='number']  {
    padding: 0 !important;
}

.tableCollection input[type="text"], .tableCollection input[type="password"], .tableCollection input[type="email"], .tableCollection input[type="number"], .tableCollection select.form-control {
    border: 0px solid #d6d6d6;
}
.tableCollection .css-1aya2g8 {
    border-width: 0px;
}

.tableCollection .css-1aya2g8, .tableCollection .css-2o5izw {
    border-radius: 0!important;
}
.tableCollection .css-rsyb7x input {
    min-width: 100px!important;
}
.input-group-addon {
    background-color: transparent!important;
}
.panel-heading input[type="text"]:focus  {
    border-right: 1px solid #44c699!important;
}
.percent-input { 
    border-right: none!important;
}



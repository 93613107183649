.swatch {
    margin-left: 5px;
    padding: 0;
    background: #fff;
    border-radius: 2px; 
    box-shadow: 0 0 0 1px rgba(0,0,0,.1);
    display: inline-block;
    cursor: pointer;
}
.color {
    width: 48px;
    height: 22px;
    border-radius: 2px; 
}
.cover {
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
}
.popUpColor {
    position: absolute;
    z-index: 2;
}

input {
    padding-right: 0px !important;
    padding-left: 3px !important;
}

.result {
    margin: 10px 5px;
    width: 20%;
    height: 32px;
    box-shadow: 0 0 0 1px rgba(0,0,0,.1);
    border-radius: 3px;
    font-size: 16px;
}
.saveColor {
    display: inline-block;
    margin: 0 0 5px 15px;
}
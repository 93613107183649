.survey-container {
  width: 100%;
  background-color: #f2f6ff;
  min-height: 100vh;
  height: auto;
  position: relative;
  overflow-y: hidden;
}

.survey-wrapper {
  max-width: 1300px;
  min-height: 100vh;
  margin: 0px auto;
  padding: 27px 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.survey-list-wrapper {
  background-color: #fff;
  color: #000;
  border-radius: 20px;
  padding: 20px 90px 30px;
  position: relative;
  z-index: 5;
}

.bg-icon-wrapper-left {
  position: absolute;
  bottom: 0;
  left: 0;
}
.bg-icon-wrapper-right {
  position: absolute;
  bottom: 50%;
  right: 0;
}

.survey-list-text-content h1 {
  text-align: center;
  margin-bottom: 40px;
}

.survey-list-text-content p {
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 40px;
}

.survey-list-wrapper .ant-form-item {
  margin-bottom: 32px;
}

.survey-list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 21px;
}

.survey-list-lang {
  font-size: 22px;
}

.survey-logo {
  width: 180px;
  height: 70px;
}

.survey-list-wrapper .form-label {
  font-size: 18px;
  margin-bottom: 10px;
}

.survey-list-wrapper .form-label span {
  color: #ff0000;
}

.survey-list-wrapper .ant-radio {
  display: none;
}

.survey-list-wrapper .scale-option {
  display: inline-block;
  width: 48px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e6ecfa;
  font-size: 18px;
  border-radius: 3px;
  cursor: pointer;
}

.survey-list-wrapper span.ant-radio + * {
  padding: 0px;
}

.survey-list-wrapper .ant-radio-wrapper-checked .scale-option {
  background-color: #a5ebb9;
}

.survey-list-textarea textarea {
  background: #ffffff;
  box-shadow: 0px 1.5px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  resize: none;
  padding: 10px;
  border: none;
  height: 180px !important;
}

.survey-list-select .ant-select {
  width: 100% !important;
  height: 64px !important;
}

.survey-list-select .ant-select-selection-item {
  display: flex;
  align-items: center;
}

.survey-list-select .ant-select-selector {
  width: 100% !important;
  height: 64px !important;
  background-color: #e6ecfa !important;
  border-radius: 3px !important;
  border: none !important;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25) !important;
}

.survey-list-multiply-select .ant-select-selection-item {
  background-color: white;
  border-radius: 8px;
  height: auto;
  margin-left: 14px;
}

.survey-list-multiply-select .ant-select-selection-item-content {
  line-height: 36px !important;
}

.survey-list-multiply-select .ant-select-clear {
  background-color: transparent;
  right: 45px;
  transform: translateY(-50%);
}

.survey-list-select-clear-icon {
  font-size: 22px;
}

.survey-list-checkbox .ant-checkbox-group {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.survey-list-checkbox .ant-checkbox-wrapper {
  display: flex;
  align-items: center;
  height: 30px;
}

.survey-list-checkbox .ant-checkbox-wrapper span:last-child {
  font-size: 14px;
}

.survey-list-checkbox .ant-checkbox-inner {
  width: 32px;
  height: 32px;
  position: relative;
  margin: 20px auto;
  border-radius: 50px;
  box-shadow: inset 0px 1px 1px white, 0px 1px 3px rgba(0, 0, 0, 0.5);
}

.survey-list-checkbox .ant-checkbox-wrapper-checked .ant-checkbox-inner {
  background-color: #a5ebb9;
  border-color: #a5ebb9;
  background-clip: content-box, padding-box;
}

.survey-list-checkbox .checkbox-text {
  margin-top: 5px;
  margin-bottom: 0px;
}

.survey-list-checkbox .ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0px;
}

.survey-list-checkbox .ant-checkbox-wrapper span:last-child {
  padding: 5px !important;
}

.survey-list-checkbox .ant-checkbox-inner::after {
  border: none;
}

.survey-list-checkbox .ant-checkbox-checked .ant-checkbox-inner::after {
  display: none;
}

.survey-list-checkbox .ant-checkbox:hover::after,
.survey-list-checkbox .ant-checkbox-wrapper:hover .ant-checkbox::after {
  border: none;
}

.survey-list-buttons {
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 30px;
}

.thanks-text {
  text-align: center;
}

.survey-btn-clear {
  width: auto !important;
  height: auto !important;
  padding: 8px 20px;
  font-size: 18px;
  background: #e6ecfa;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  border: none;
  transition: all 0.3s ease;
}

.survey-btn-clear span {
  padding: 0 !important;
}

.survey-btn-clear:hover {
  color: #e6ecfa;
  background-color: #000;
}

.survey-btn-submit {
  width: auto !important;
  height: auto !important;
  padding: 8px 20px;
  font-size: 18px;
  background: #a5ebb9;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  border: none;
  transition: all 0.3s ease;
}

.survey-list-buttons .survey-btn-submit.ant-btn:focus {
  background: #a5ebb9;
  color: #000;
}

.survey-list-buttons .survey-btn-clear.ant-btn:focus {
  background: #e6ecfa;
  color: #000;
}

.survey-btn-submit span {
  padding: 0 !important;
}

.survey-btn-submit:hover {
  color: #a5ebb9;
  background-color: #000;
}
.survey-footer {
  margin-top: 90px;
  z-index: 20;
  position: relative;
}

.success-text {
  text-align: center;
  margin-bottom: 80px;
}

@media (max-width: 768px) {
  .survey-wrapper {
    padding: 10px 20px;
  }
  .survey-list-wrapper {
    padding: 10px;
    position: relative;
    z-index: 5;
  }
  .survey-list-text-content p {
    font-size: 16px;
    line-height: 22px;
  }
  .survey-btn-clear {
    padding: 5px 10px;
  }

  .survey-btn-submit {
    padding: 5px 10px;
  }

  .survey-list-checkbox .ant-checkbox-inner {
    width: 28px;
    height: 28px;
  }

  .survey-list-select .ant-select-selector {
    height: 48px !important;
  }
}

.wrapper{
	top:  45px;
}

.sidebar{
	width: 170px;
	position: absolute;
	top:  50px;
}

#main-content.merge-left{
	width: calc(100% - 80px);
}

.hidе-group-label .ant-menu-item-group-title{
	display: none;
}

.navbar-logo-collapse img{
	width: 100px;
}

.purple-menu .ant-menu-submenu .ant-menu-submenu-title{
	padding-left: 10px;
}

.purple-menu .ant-menu-item{
	padding-left: 10px!important;
}
.sidebar.sidebar-collapsed .purple-menu .ant-menu-item{
	padding-left: 15px!important;
}

.sidebar.sidebar-collapsed .purple-menu .ant-menu-item.menu-expand{
	padding-left: 15px!important;
}

.sidebar.sidebar-collapsed{
	width: 80px!important;
}

.sidebar ul li a{
	display: inherit!important;
	font-size: 15px!important;
	padding:  0!important;
}

.sidebar .purple-menu .ant-menu-submenu-selected,
.sidebar .purple-menu .ant-menu-item-selected a,
.submenu-ui .ant-menu-title-content a,
.sidebar .purple-menu .ant-menu-item a:hover,
.sidebar .purple-menu .ant-menu-title-content a{
	color:  #444;
	font-size: 15px;
}
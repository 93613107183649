.rdtPicker {
    display: block;
    position: static;
}

.pivoting .table-inbox{
    position: relative;
    overflow: auto;
    margin: 0 15px;
    background: #f8f9fc;
    border-radius: 4px;
    border: 1px solid #a6a6a6;
    font-size: 13px;
    width: 100%;
    height: 100%;
}

.load{
    padding: 5px;
}
.loading{
    margin-left: 15px;
    margin-bottom: 15px;
    font-size: 15px;

}
.sectionBackground {
    background: #f0eae7;
    overflow-x: hidden;
    height: -webkit-fill-available;
}

.pvtDropdownValue {
    padding: 2px 5px;
    font-size: 12px;
    text-align: left;
}

.pvtDropdownCurrentOpen {
    border-radius: 4px 4px 0 0;
}

.pvtDropdownCurrent {
    text-align: left;
    border: 1px solid #a2b1c6;
    border-radius: 4px;
    display: inline-block;
    position: relative;
    width: 210px;
    box-sizing: border-box;
    background: #fff;
}
.pvtUi {
    color: #2a3f5f;
    font-family: Verdana;
    border-collapse: collapse;
}

.pvtUi {
    width: 100%;
}

.pvtDropdownMenu {
    background: #fff;
    position: absolute;
    width: 100%;
    margin-top: -1px;
    border-radius: 0 0 4px 4px;
    border: 1px solid #a2b1c6;
    border-top: 1px solid #dfe8f3;
    box-sizing: border-box;
}

.pvtSearch {
    min-width: 270px!important;
}

.pvtUi { width: 100%; }
.pvtVertList { width: 15%; }

#pivot .date-range .ant-calendar-picker{
    width: 250px!important;
    min-width: 0px!important;
    background-color: #fff;
}
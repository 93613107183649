.ant-select-dropdown.ant-select-dropdown-placement-bottomLeft{
    z-index: 10001;
}
.crm-profile .account-profile-information-general{
  position: relative;
   z-index: 20;
 }
 .account-profile-tabTable{
    color: black;
 }
.crm-profile .account-profile-tabs .ant-tabs .ant-tabs-small-bar .ant-tabs-tab, .crm-profile .account-profile-tabs .ant-menu-submenu-title{
    padding: 8px 16px;
    background: transparent;
    border: 0;
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 3px;
}
.crm-profile .account-profile-tabs .ant-tabs-nav .ant-tabs-tab-active, .crm-profile .account-profile-tabs .ant-menu-submenu-active, .crm-profile .account-profile-tabs .ant-menu-submenu-title:hover{
    color: #4eb0e2;
}
.crm-profile .account-profile-tabs .ant-tabs-ink-bar{
    background-color: #4eb0e2;
}
.crm-profile .account-profile-tabs .ant-table-row-cell-break-word{
    word-break: inherit;
    overflow-wrap: normal;
}
.crm-profile .account-profile-tabs .ant-table{
  font-size: 15px;
}
.crm-profile .crmActivities ul li .conversation-text.no-activities{
      border: none!important;
}
.crm-profile  .crmActivities ul li label{
   margin-right: 5px;
 }
.crm-profile  .crmActivities .static-buttons{
  top: 0;
}
.crm-profile .crmActivities .tread-btn{
    border: 1px solid #babab7;
    padding: 5px 7px;
    border-radius: 4px;
    margin-right: 50px;
}
.crm-profile .crmActivities .tread-btn a{
  text-decoration: none;
}
.crm-profile .crmActivities .tread{
    float: right;
    border:1px solid lightgray;
    padding: 15px;
    border-radius: 4px;
    margin-bottom: 20px;
  }

.crm-profile .account-profile-tabs .ant-tabs-bar {
    margin: 0px;
  }
.crm-profile .account-profile-tabs .ant-menu-horizontal {
    line-height: 25px;
  }
.crm-profile .account-profile-tabs .btn-round{
   top: 0px;
   right: 20px;
   z-index: 1;
}
.crm-profile .account-profile-tabs .btn-round .ion-android-add {
    line-height: 50px;
    text-align: center;
    color: white;
    font-size: 30px;
 }
.inlineHover > i {
    margin-right: 5px;
    margin-left: 2px;
    font-size: 15px;
    cursor: pointer;
}
.inlineHover.input-select2 > i.icon.ion-checkmark-round,
.inlineHover.input-select2 > i.fa.fa-times-circle-o {
    position: relative;
    top: 8px;
}
.inlineHover > i.icon.ion-edit,
.inlineHover > i.icon.ion-android-delete {
    visibility: hidden;
}
.inlineHover > .icon.ion-checkmark-round {
    margin-left: 5px;
}

.inlineHover:hover > i.icon.ion-edit,
.inlineHover:hover > i.icon.ion-android-delete {
    visibility: visible;
    cursor: pointer;
}

.inlineHover > i.icon.ion-edit {
    font-size: 14px;
}
.inlineHover > i.icon.ion-checkmark-round {
    color: #44c699;
}

.inlineHover > i.icon.ion-edit {
    color: #d3d3d3;
}

.inlineHover > i.icon.ion-android-delete {
    color: #d3d3d3;
}

.inlineHover > i.fa.fa-times-circle-o {
    color: #d3d3d3;
}

.inlineHover .tags{
    word-break: normal;
    display: block;
}

.inlineHover .tags li{
    display: inline-block;
    margin-bottom: 5px;
}

.inlineHover>input {
    padding-right: 0px !important;
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid #44c699;
    padding-left: 0px !important;
    margin-right: 10px;
    letter-spacing: 0;
}

.inlineHover>input:focus {
    border: none;
    border-bottom: 1px solid #44c699;
    box-shadow: none;
}
.inlineHover>input[type=date] {
    line-height: 16px;
    border: none;
    cursor: pointer;
}

.inlineHover>[type=date] {
    background:#fff;
}

.inlineHover>select {
    border-radius: 6px;
    background: transparent;
    width: 80%;
    height: auto;
    -webkit-appearance: menulist-button;
}
.inlineHover>[type=date]::-webkit-inner-spin-button {
    display: none;
    -webkit-appearance: none;
}
.inlineHover>[type=date]::-webkit-clear-button
{
    color: #a0a0a0;
    font-size: 12px;
}
.inlineHover>[type=date]::-webkit-calendar-picker-indicator {
    color: #a0a0a0;
    font-size: 14px;
}

.inlineHover .icon-before-input {
    margin-right: 7px;
}

.inlineHover .icon-before-input i{
    color: #a0a0a0;
}

.inlineHover .button.green {
    font-size: 14px;
    margin-left: 10px;
}
#comments {
    padding: 10px !important;
}

#comments input {
    height: 40px;
}

#comments input[type='text'] {
    width: 80%;
    min-width: 190px!important;
    border: 1px solid #ccc;
    border-right: none;
    border-radius: 5px 0 0 5px;
}

#comments input[type='button'] {
    width: 20%;
    border: 1px solid #ccc;
    border-left: none;
    border-radius: 0 5px 5px 0;
    background-color: #44c699;
    color: #fff;
}

#comments .single-message {
    margin-top: 30px;
}

#comments .image-container {
    padding: 0;
    text-align: right;
}

#comments .single-message img {
    width: 30px;
    border-radius: 50%;
    height: 30px;
}

.content-container {
    padding-left: 0;
}

#comments .content-container h3 {
    text-align: center;
}


#comments .content-container .user-comments:nth-child(2) .comment-content {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    word-wrap: break-word;
}

#comments .content-container .user-comments:last-child .comment-content {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    word-wrap: break-word;
}

#comments .single-message .author {
    font-weight: bold;
    text-align: center;
}

#comments .single-message .publish-date {
    font-weight: bold;
    text-align: right;
    color: #ccc;
    width: 100%;
    padding-right: 15px;
}

#comments .single-message .comment-content {
    padding: 10px;
    background: #fbfafb;
    border: 1px solid #e3e3e3;
    color: black;
    margin-left: 10px;
    margin-top: 5px;
    cursor: pointer;
    word-wrap: break-word;
}

#comments .single-message .comment-content .comment-actions {
    text-align: right;
}

#comments .comment-actions a {
    color: inherit;
    cursor: pointer;
    text-decoration: none;
}

.modalConfirms {
    background-color: #fff;
    border-radius: 5px;
    width: 40%;
    height: auto;
    min-height: 100px;
    margin: 0 auto;
}

.modalConfirms .panel-body {
    width: 90%;
}

.modalConfirms .panel {
    border: 0;
    box-shadow: none;
}

.modalConfirms header {
    display: none!important;
}
.account-profile .account-profile-information .ant-card-head-title{
    font-size: 18px;
}
.account-profile .line span{
    color: black;
    font-size: 16px;
}
.account-profile .content .notes p{
    font-size: 18px;
}
.account-profile .account-profile-card-header{
    font-size: 16px;
}
.account-profile .account-profile-popup-form label{
    font-size: 16px;
}
.line-address>i.icon {
    visibility: hidden;
    margin-right: 8px;
}

.line-address:hover > i.icon {
    visibility: visible;
    font-size: 15px;
    cursor: pointer;
}
.line-address {
    justify-content: right!important;
    font-size: 16px;
    margin-left: 5px;
    margin-bottom: 12px;
    margin-right: 25px;
}
.line-note {
    justify-content: right!important;
    font-size: 14px;
    margin-left: 5px;
    margin-bottom: 12px;
    margin-right: 25px;
}
.line input {
    min-width: 40px!important;
}
.btn-crm {
    float: none;
}

.crm-profile .account-profile-other-information-more{
    position: relative;
/*    z-index: 0;*/
}

.crm-profile .account-profile-information.comment{
    z-index: 8;
}

.account-profile-other-information textarea {
    background: rgb(255, 255, 255);
    border: 1px solid rgb(202, 202, 201);
    border-radius: 3px;
    box-shadow: none;
    margin-top: 0px;
    margin-bottom: 0px;
    height: 140px;
}

.add-contact {
    font-weight: bold!important;
}

.add-contact>i {
    margin-right: 7px;
}

.add-contact:hover,  .add-contact:active, .add-contact:focus{
    text-decoration: none;
    color: #110872;
}

.infoMessage{
   margin: 20px 0px;
   padding-left: 0px;
   font-size: 16px;
}

.table .btn-group .fa-print{
    padding-bottom: 6px;
}
.callIcons span.circle{
    height: 25px;
    width: 25px;
    background-color: #74c15e;
    border-radius: 50%;
    display: inline-block;
    color: white;
    text-align: center;
    line-height: 26px;
    cursor: pointer;
    font-size: 12px;
    margin-right: 10px;
  }
.callIcons span.circle a{
      color: white;
    }

 .item-profile-right > div {
          margin-top: 0px!important;
      }

.custom-data.ant-tabs{
    overflow: visible!important;
    padding-top: 60px;
}
.newProjectsButton{
    background: #4eb0e2!important;
    width: 25px!important;
    height: 25px!important;
    padding: 0!important;
    border-radius: 100%!important;
    line-height: 18px!important;
    color: white!important;
    border: 0!important;
    margin-left: 14px!important;
    font-size: 18px!important;
}
@media only screen and (max-width: 900px) {
  .line.line-accountContact{
    justify-content: flex-start!important;
  }
}

.pointer {cursor: pointer;}
.line.line-accountContact{
  border-radius: 4px;
  border: 1px solid lightgray;
  border-radius: 4px;
  padding: 10px;
  margin: 15px;
}
.line-accountContact .contactName{
    font-weight: 600;
}
i.contactDelete :hover{
    color: red !important;
    font-weight: bold !important;
    outline: none;
}

.project:hover + .project-hint{
  display: table;
}

.mySelect__value-container{
    width: 60%;
   }

   .button-primary-ant {
    border: 1px solid #44c699 !important;
    background: #44c699 !important;
    color: #fff !important;
    min-width: 125px !important;
   /* border-radius: 3px;
    height: 35px;
    float: left;
    line-height: 35px;
    padding: 0 15px;
    text-align: center;
    margin: 15px;
    min-width: 125px;
    cursor: pointer;*/
   }

.crm-profile .dnd-icon{
    color: #e50000;
}
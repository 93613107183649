/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 19.01.2021, 09:12:58

.Toastify__toast-container--bottom-center {
    background: #44c699;
    margin: 0 auto;
    text-align: center;
    height: 40px;
    font-size: 18px;
    line-height: 2;
    color: white;
    border-radius: 10px 10px 0px 0px;
}   Author     : Rossana Pencheva <rossana.ruseva@gmail.com>
*/
.Toastify__toast-container {
    z-index: 9999;
    -webkit-transform: translate3d(0, 0, 9999px);
    position: fixed;
    padding: 4px;
    box-sizing: border-box;
    color: #fff;
     bottom: 0px;
    left: 10%;
    right: 10%;
    width: 80% !important;
    float: center;
    text-align: center;
}
  .dms_new_menu{
    padding: 20px 15px 0;
    display: table;
    min-height: 68px;
    width: 100%;
    background: #fff;
    border-bottom: 2px solid #e8e7ea;
    margin-bottom: 0;
}
.dms_new_menu .navigation{
   display: flex;
   align-items: center;
}
.dms_new_menu h3.module-title {
    margin: 0px;
}
  .dms_new_menu .button-primary{
    margin: 15px;
  }
  .dms-upload{
    display: flex;
  }
  .dir_row{
    margin: 10px 20px;
  }
  .dir{
    padding: 5px;
  }
  .dir_folder{
    background: #e0e0f5;
    border: 1px solid #d6d6e0;
    width: 100%;
  }
  .dir_element{
    max-height: 130px;
    font-size: 100px;
    color: white;
    text-align: center;
  }
  .dir_element:hover{
    color: white;
  }
  .dir_icon:focus{
    outline: none;
  }
  .dir_link{
    padding: 10px 0 10px 0;
    margin: 0 15px;
    text-decoration: none;
  }
  .dir_link:hover{
    border-bottom: 2px solid #f31c3f;
    text-decoration: none;
  }
  .dir_card{
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum';
    position: relative;
    background: #fff;
    border-radius: 2px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    border: 1px solid #e8e8e8;
    padding: 6px;
  }
 .dir_image{
   position: relative;
   left: 0;
   right: 0;
   z-index: 1;
   display: block;
   background-position: left;
   background-repeat: no-repeat;
   background-size: cover;
   height: 200px;
   -webkit-filter: blur(9px);
   -moz-filter: blur(9px);
   -o-filter: blur(9px);
   -ms-filter: blur(9px);
   filter: blur(9px);
  }
  .dir_image2{
    position: absolute;
    left: 0;
    right: 0;
    top: 5px;
    z-index: 99;
  }
  .dir_image2 img{
   max-width: 100%;
   max-height: 200px;
   display: block;
   margin: auto;
   width: auto;
  }
  @media only screen and (max-width: 767px){

  }
 /* .Toastify__toast-container--bottom-center {
    bottom: 1em;
    left: 30%;
    right: 30%;
    margin-left: -160px;
    width: 80% !important;
    font-size: 16px;
    background: #44c699;
}
.Toastify__toast--default {
    padding: 30px;
}*/

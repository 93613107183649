/*
    Created on : Nov 28, 2019, 2:25:14 PM
    Author     : veronika
*/
body{
  font-family: 'ProximaNovaA',sans-serif;
  font-weight: normal;
  color: #32323A;
}
a{
  font-family: 'ProximaNovaA',sans-serif;
  font-weight: normal;
  color: #32323A;
}
#main-content{
    width:calc(100% - 190px);
    float:right;
    background: #f0eae7;
    font-family: 'ProximaNovaA',sans-serif!important;
}
.grid-panel a, .grid-panel a:hover, .moduleSubMenu a, .moduleSubMenu a:hover{
    color: #222227;
}
/*CUSTOM MODAL*/
.customModal{
    display: block;
    background-color: rgba(0, 0, 0, 0.45);
    font-family: 'ProximaNovaA',sans-serif;
}
.customModal-title{
    margin: 0;
    color: black;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    word-wrap: break-word;
}
.customModal-body{
    z-index: 10001;
    position: relative;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-radius: 4px;
    -webkit-box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    pointer-events: auto;
}

.ql-container {
    height: 400px;
}
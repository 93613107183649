.import.tableCollection table{
    background-color: #fff;
}
.import.tableCollection .breadcrumb{
    margin: 10px 0 5px -10px;
}
 .import.tableCollection .breadcrumb a {
    font-size: 18px;
    font-weight: bold;
    color: #4DA1BE;
    text-decoration: none;
}
.import.tableCollection .breadcrumb i {
    font-size: 14px;
    margin-right: 7px;
    font-weight: bold;
    color: #4da1be;
}

.import.tableCollection .import-buttons{
    margin-bottom: 30px;
    height: 50px;
}

.import.tableCollection .import-buttons button{
    margin-left: 0;
}

.import.tableCollection .errors{
    min-height: 200px;
    background-color: white;
    width: 700px;
    padding-left: 30px;
    padding-top: 30px;
    font-size: 17px;
}

.import-data{
    background-color: white;
    margin: 100px;
    width: 60%;
    height: 280px;
    padding: 20px;
}

.import-data.disabled{
    pointer-events: none;
    opacity: 0.5;
    background: #CCC;
}
.field img {
    width: 40px;
    border-radius: 100%;
    height: 40px;
}

.input-board {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    background-color: #f0eae7;
    border: 4px dashed #95d472;
    height: 30%!important;
}

.input-board.beige {
    background-color: beige;
}

.input-board .upload-button {
    margin: 10px 0;
}

.input-board .upload-button i {
    margin: 0;
}
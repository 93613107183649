.sticky-grid__header {
    position: sticky;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    z-index: 3;
}

.sticky-grid__header__base {
    z-index: 3;
    background: #eaf0f5;
    position: sticky;
    left: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 10px;
    border: 1px solid #ccc;
    font-weight: bold;
}

.sticky-grid__header__scrollable {
    position: absolute;
}

.sticky-grid__header__scrollable__column {
    position: absolute;
    background-color: #eaf0f5;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 10px;
    border: 1px solid #ccc;
    font-size: 14px;
    line-height: 1.42857143;
    color: #333;
    font-weight: bold;
}

.sticky-grid__sticky-columns__container {
    position: sticky;
    left: 0;
    z-index: 2;
    background: #eaf0f5;
    width: min-content;
}

.sticky-grid__sticky-columns__row {
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid #ccc !important;
    z-index: 10;
    /*padding-left: 10px;
    border-bottom: 1px solid gray;
    border-right: 1px solid gray;*/
}

.sticky-grid__data__container {
    position: absolute;
}

.sticky-grid__data__column {
    /*  display: flex; */
    flex-direction: row;
    align-items: left;
    border: 1px solid #ccc;
    /* padding-left: 10px;
     border-right: 1px solid gray;
     border-bottom: 1px solid gray;*/
}

.border-green {
    border: 1px solid greenyellow !important;
}

div + div.border-green {
    border: 1px solid greenyellow !important;
}

.sticky-grid__container {
    background: white;   
}

.div-text-center {
    text-align: center;

}

.div-text-right {
    text-align: right !important;
}

.highlightField {
    background-color: #ff000047 !important;
}



/*
    Created on : Sep 15, 2020, 2:59:06 PM
    Author     : vk
*/
/*body{
        background-color: #fbfaff!important;
}*/

.login{
  font-family: 'ProximaNovaA',sans-serif!important;
  background-color: #fbfaff!important;
}
.login-top{
    padding: 30px 0;
    display: flex;
    align-items: baseline;
}
.login-hero{
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  padding-left: 70%;
}
.login-link{
  text-align: center;
  color: #665e5e;
  font-weight: 600;
  margin-right: 20px;
}
.login-button-white{
  width: 154px;
  border-radius: 17px;
  box-shadow: 0 2px 7px 0 #ded9fa;
  background-color: white;
  text-align: center;
  color: #665e5e;
  padding: 10px;
  font-weight: 600;
}
.login-mainRow{
    padding: 7% 0;
}
.login-body{
  background: #fbfaff;
}
.login-title{
  font-size: 37px;
  font-weight: bold;
  line-height: 1.27;
  letter-spacing: 0.1px;
  color: #333333;
  max-width: 560px;
  margin-bottom: 40px;
}
.login-title-white{
  font-size: 30px;
  font-weight: bold;
  line-height: 1.3;
  letter-spacing:0.1px;
  text-align: center;
  color: white;
}
.login-subTitle{
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  color: #adb3b5;
}
.auth-button{
  width: 156px;
  border-radius: 23.5px;
  background-color: #ff3465;
  padding: 15px 25px;
  float: right;
  color: white;
  text-align: center;
  text-transform: uppercase;
  border: none;
  font-weight: bold;
}
.auth-button:hover{
  box-shadow: 0 2px 7px 0 #ded9fa;
}
.buttons-section{
  margin: 30px 0px;
}
.demo-button{
  width: 156px;
  padding: 7px 20px;
  border-radius: 23.5px;
  background-color: #ff3465;
  color: white;
  text-align: center;
  text-transform: uppercase;
  border: none;
}
.demo-button:hover, .demo-button:active, .demo-button:focus{
  color: white;
}
/* Form */
.login-input input{
  height: 60px;
  border-radius: 4px;
  border: solid 1px #afafaf;
  background-color: white;
}
.login-input label{
  font-weight: 600;
 }

 .create-button{
  width: 156px;
  border-radius: 23.5px;
  background-color: #ff3465;
  padding: 15px 25px;
  float: right;
  color: white;
  text-align: center;
  text-transform: uppercase;
  border: none;
  font-weight: bold;
  padding: 1px;
  height: 40px;
}

/* MOBILE */
@media only screen and (max-width: 900px) {
  .register{position: absolute;top: 0;left: 0;right: 0;height: auto;}
  .login .bg{display: none;}
  .login-top{display: block;}
  .login-link{margin-left: 20px;}
  .login-mainRow{padding: 0px; margin: 40px 15px;}
  .demo-col{display: none;}
  .login-title{text-align: center;width: 100%;font-size: 30px;}
}

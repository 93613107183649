.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.Toastify__toast-container {    
  margin: 0 auto;
  text-align: center;
  height: 50px;
  font-size: 18px;
  line-height: 2;
  color: white;
  border-radius: 10px 10px 0px 0px;
}

.Toastify__toast {  
  position: relative;
  min-height: 84px;
  box-sizing: border-box;
  margin-bottom: 1rem;
  padding: 8px;
  color: white;
  border-radius: 10px 10px 0px 0px;
  box-shadow: 0 1px 10px 0 rgb(0 0 0 / 10%), 0 2px 15px 0 rgb(0 0 0 / 5%);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  max-height: 800px;
  overflow: hidden;
  font-family: sans-serif;
  cursor: pointer;
  direction: ltr;
}
.Toastify__toast Toastify__toast--error{
   border-radius: 10px 10px 0px 0px;
}
.grow-font-size {
   border-radius: 10px 10px 0px 0px;
}

.black-background {
 border-radius: 10px 10px 0px 0px;
}

.Toastify__toast-container--bottom-center {
 border-radius: 10px 10px 0px 0px;
}

.Toastify__toast--success {
border-radius: 10px 10px 0px 0px !important;
background: #44c699 !important;
}  
.Toastify__toast--warning {
border-radius: 10px 10px 0px 0px !important;
}
.Toastify__toast--error {
border-radius: 10px 10px 0px 0px !important;
 background: #e50000 !important;
}
.toastBody {
border-radius: 10px 10px 0px 0px !important;
}
.toastProgress {
border-radius: 10px 10px 0px 0px !important;
}

.Toastify__toast-container--bottom-center {
  bottom: 1em;
  left: 52%;
  /* right: 10%; */
  width: 80%;
  transform: translateX(-50%);

}

.printData {
  background-color: white;
  margin: auto;
  height: 100%;
  max-width: 70%;
  margin-bottom: 50px;
  border-radius: 2px;
  box-shadow: 0 0 7px 0 #d4d4d4;
  overflow: auto;
  padding: 40px;
}

.form-select-basic {
  display: block;
  width: 240px;
  height: 60px;
  padding: .375rem 2.25rem .375rem .75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 14px;
  color: #212529;
  background-color: #fff;

}

@media (max-width: 767px) {
  body{
    overflow: scroll;
  }

  .ant-drawer-content-wrapper{
    width: 100%!important;
  }

  .mobileMenu .ant-menu-item{
    font-size: 22px!important;
  }
  .mobileMenu .ant-menu-submenu-title{
    font-size: 22px!important;
  }
}

.account-profile .account-profile-information {
  border: 1px solid lightgray;
  background: white;
  border-radius: 2px;
  box-shadow: 0 0 7px 0 #d4d4d4;
  padding-bottom: 15px;
  z-index: 10;
}

.small-select.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
  height: 25px;
}

.chrome-picker{
  width: 150px !important;
  transform: translate(-86px, -4px);
}

.block-picker{
  width: 150px !important;
  transform: translate(-86px, -4px);
}

.swatches-picker {
  width: 182px !important;
  height: 100px;
  transform: translate(-102px, -93px);
  z-index: 5;
}

.color_picker {
  position: absolute;
  transform: translate(-205px, -2px);
  z-index: 15;
}


.dataTable input[type="text"],
.dataTable input[type="number"],
.dataTable .css-1aya2g8{
	margin-bottom: 0;
	border-radius: 0;
	border: none;
}

.dataTable td{
	border: 1px solid #ccc;
}

.dataTable thead {
    border: 1px solid #ccc;
    background-color: #eaf0f5;
}

.dataTable thead td {
    border: 0;
    padding: 10px 3px 0 3px !important;
    font-weight: bold;
}
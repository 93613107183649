.item-requestPage .ant-calendar-picker-input.ant-input{
    border: none;
    border-radius: 0px;
    margin-bottom: 0px;
}
.item-requestPage .ant-calendar-range-picker-input{
  cursor: pointer;
}
.listGrid{
  box-shadow: inset 4px 1px 7px 0 rgba(0, 0, 0, 0.06);
  background-color: #fbfaff;
  z-index: 999;
}
.list-header{
  display: flex;
  padding-bottom: 10px;
  margin: 0 15px;
  align-items: center;
}
.list-header .ant-select-selection__rendered, .listSingle-body .ant-select-selection__rendered{
  line-height: 28px!important;
}
.list-headerTitle{
  font-size: 18px;
  margin-right: 10px;
}
.list-header .filterBox{
   background: white;
   border-radius: 4px;
   box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.05);
   border: solid 1px #e5e0ea;
   padding: 15px 20px;
   text-align: center;
   text-transform: uppercase;
   font-size: 16px;
   cursor: pointer;
   text-decoration: none;
}
.list-tableRow .top{
  font-weight: 600;
  min-width: 330px;
}
.list-tableRow .first{
  padding-right: 8px;
  border-right: 1px solid grey;
}
.list-tableRow .second{
  padding-left: 8px;
}
tr.list-tableRow.focused {
    background: #e6f7ff;
    box-shadow: -3px 3px 4px 0 rgba(0, 0, 0, 0.05);
}
.list-tableRow .list-date, .list-tableRow .list-stage{
  color: #879697;
}
.list-tableLink{
  cursor: pointer;
  text-decoration: none;
}
.list-tableLink:hover{
  text-decoration: none;
}
.listFilters-select .ant-select-selection--single {
    height: 41px;
  }
.listSingle-info {
  background: white;
}
.listSingle-header{
  margin: 15px 0px 25px;
}
.listSingle-shodow{
  box-shadow: inset 4px -1px 7px 0 rgba(0, 0, 0, 0.06);
  background-color: #fbfaff;
}
.listSingle-body{
  margin-top: 20px;
}
.listSingle-info .actions{
  margin-top: 10px;
}
.listSingle-info .link-button{
  margin-left: 6px;
  text-decoration: none;
  text-transform: uppercase;
  margin-right: 15px;
  font-weight: 600;
}
.listSingle-info .link-button a{
  color: #4eb0e2;
}
 .listSingle-table{
   margin-bottom: 50px;
 }
.listSingle-item{
     border: 1px solid #dae1e4;
     border-radius: 7px;
     background-color: #fff;
     margin: 15px 0px;
     padding: 15px 18px 9px;
     width: 100%;
}
.listSingle-item .qty{
    text-align: right;
    color: #222;
    font-size: 18px;
    font-weight: 700;
}
.listSingle-info .stage {
    padding-left: 10px!important;
}
.listSingle-info .stage.greenBorder {
    border-left: 2px solid #44c699;
}
.listSingle-info .stage.orange {
    border-left: 2px solid #ffa500;
}
.request-tabs-tab{
    height: 40px;
    margin: 0;
    margin-right: 2px;
    padding: 0 16px;
    line-height: 38px;
    background: #fafafa;
    border: 1px solid #e8e8e8;
    border-radius: 4px 4px 0 0;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.request-tabs-tab.active{
  height: 40px;
    color: #1890ff;
    background: #fff;
    border-color: #e8e8e8;
    border-bottom: 1px solid #fff;
    font-weight: 600;
  }
.request-tabs-tab.active:before{
    border-bottom: 2px solid transparent;
  }

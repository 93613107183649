/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 11.01.2021, 16:32:55
    Author     : Rossana Pencheva <rossana.ruseva@gmail.com>
*/


.button-primary {
    border: 1px solid #44c699;
    background: #44c699 !important;
    color: #fff !important;
    border-radius: 3px;
    height: 35px;
    float: left;
    line-height: 35px;
    padding: 0 15px;
    text-align: center;
    margin: 15px;
    min-width: 125px;
    cursor: pointer;
}

.ant-input.is-invalid {    
    webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-variant: tabular-nums;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum', "tnum";
    position: relative;
    display: inline-block;
    width: 100%;
    height: 60px;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    line-height: 1.5;
    background-color: #fff;
    background-image: none;
    border: 1px solid #e50000;
    border-radius: 4px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.invalid-feedback {
    color: #e50000;    
}

.signatureCanvas {
  border: 1px solid black;
  width: 300px;
  height: 200px;
  cursor: crosshair;
}

.page-container {
  position: relative;
  margin-top: 120px;
  display: flex;
  justify-content: center;
  align-items: start;
  gap: 44px;
}

.page {
  width: 21cm;
  height: 29.7cm;
  padding: 2cm;
  margin: 1cm auto;
  border-radius: 5px;
  background: white;
  /* top: 50px; */
  /* left: 420px; */
  overflow-y: scroll;
}

/* .info-container {
  display: flex;
  justify-content: center;
  width: 100%;
  background: blueviolet;
} */

/* .side-container {
  width: 50%;
} */

.document-container {
  overflow: hidden;
}

.actions-container {
  display: flex;
  flex-direction: column;
  /* justify-content: end; */
  align-items: center;
  gap: 16px;
  z-index: 1;
  /* width: 50%; */
}

.actions-container p {
  font-weight: 700;
  font-size: 18px;
}

.draggable-container {
  position: absolute;
  bottom: 0;
  left: 0;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  /* margin: 0px 16px; */
}

@media (max-width: 1652px) {
  .page-container {
    display: flex;
    flex-direction: column-reverse;
    justify-content: start;
    align-items: center;
  }

  .side-container {
    display: flex;
    justify-content: center;
    align-items: start;
    gap: 16px;
  }
}
@media (max-width: 720px) {
  .page-container {
    display: flex;
    flex-direction: column-reverse;
    justify-content: start;
    align-items: center;
  }

  .side-container {
    /* display: flex; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    /* padding: 0px; */
    width: 100%;
  }

  .page {
    /* width: 21cm; */
    height: 29.7cm;
    padding: 2cm;
    margin: 1cm auto;
    border-radius: 5px;
    background: white;
    /* top: 50px; */
    /* left: 420px; */
    overflow-y: scroll;
  }
}

* {
  scrollbar-width: thin; /* "thin", "auto", or "none" */
}

/* Set the color of the scrollbar thumb and track */
* {
  scrollbar-color: #888 #eee; /* thumb-color track-color */
}

/* For Webkit-based browsers like Chrome and Safari */
/* Set the width of the scrollbar */
::-webkit-scrollbar {
  width: 10px; /* You can adjust this value */
}

/* Set the color of the scrollbar thumb */
::-webkit-scrollbar-thumb {
  background-color: #888; /* You can use any color */
}

/* Optionally, set the color of the scrollbar track */
::-webkit-scrollbar-track {
  background-color: #eee; /* You can use any color */
}

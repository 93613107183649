.activity-calendar{
	margin: 0 0px 20px 0px;
}

.activity-calendar .dashboard{
	margin-bottom: 20px;
}
.activity-calendar .taskTitle{
	white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 25px;
}

.activity-calendar .dashboard .dropdown-menu{
	position: absolute;
	left: 260px;
	font-size: 16px;
}

.activity-calendar .calendar{
	 height: 800px;
	 background-color: #fff;
	 padding: 30px;
}

.activity-calendar .calendar-toolbar{
	display: flex;
	margin-bottom: 20px;
}

.activity-calendar .calendar-toolbar .items{
	margin-left: 20px;
	cursor: pointer;
	font-size: 16px;
	font-weight: bold;
}
.activity-calendar .calendar-toolbar .items:first-child{
	margin-left: 0px;
}
.activity-calendar .calendar-toolbar .items.itemActive{
		color: #4eb0e2;
    border-bottom: 2px solid #4eb0e2;
		padding-bottom: 5px;
	}
.activity-calendar .calendar-toolbar .nextMonth{
	margin-left: 20px;
	font-size: 16px;
}
.activity-calendar .calendar-toolbar .currentMonth h2{
	margin: 0px 0px 15px 0px;
}

.activity-calendar .calendar-toolbar .currentMonth h2::first-letter {
  text-transform: uppercase;
}

.activity-calendar .filters{
	position: absolute;
	right: 20px;
	cursor: pointer;
	font-size: 16px;
	margin-right: 10px;
}
.activity-calendar .filters img{
	width: 15px;
	margin-right: 5px;
}
.activity-calendar .calendar .activeFilters{
    right: 20px;
    top: 60px;
		font-style: italic;
		margin: 10px 10px 10px 0px;
}
.activity-calendar .filter-type{
	margin-bottom: 30px;
}

.activity-calendar .filter-type input{
	margin-right: 7px;
}
.activity-calendar .filter-type input[type="text"]{
	max-width: 180px!important;
}
.activity-calendar .filter-type #eventsAll, .activity-calendar .filter-type #employeesAll{
	margin-left: 50px;
}
.activity-calendar .panel-body .filter-type .filterLabels{
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 500;
    letter-spacing: .7px;
    font-size: 15px;
    text-transform: capitalize;
}
.activity-calendar .panel-body .filter-type label{
	 color: inherit;
}
.activity-calendar .panel-body .filter-type h3{
		padding: 0px 0 10px 0;
    font-size: 22px;
	}
.activity-calendar .newPanel .panel-body .buttons{
	margin: 0px;
}
.activity-calendar .newPanel .panel-body .resultsLabelDate{
	font-size: 16px;
	color: #000;
}
.activity-calendar .newPanel .panel-body .resultsLabelInfo{
	font-weight: bold;
}
.activity-calendar .newPanel .panel-body span.resultsLabel{
	 font-weight: normal;
}
.activity-calendar .newPanel .panel-body #action-menu {
    cursor: pointer;
    font-size: 24px;
    color: lightgray;
}
.activity-calendar .newPanel .panel-body #action-menu i {
    float: right;
}
.activity-calendar .newPanel .panel-body #action-menu:hover .account-action {
    display: block;
}
.activity-calendar .newPanel .panel-body .account-action{
	  position: inherit;
    right: 7px;
    width: 5%;
    float: right;
    border: 1px solid lightgray;
    background: white;
    border-radius: 2px;
    box-shadow: 0 0 7px 0 #d4d4d4;
    font-size: 16px;
    font-style: normal;
    z-index: 1039;
    top: 22px;
    display: none;
    min-width: 140px;
}
.activity-calendar .newPanel .panel-body .account-action li a {
    display: list-item;
    text-decoration: none;
    list-style: none;
    padding: 10px 15px 10px 15px;
}
.activity-calendar .newPanel .panel-body .status-breadcrumb{
    border: 1px solid #d6d6d6;
	}
.activity-calendar .newPanel .panel-body .status-container{
		padding: 0px 30px 0px 40px;
}
.activity-calendar .moduleSubMenu .button-primary i{
	 display: none;
}
.activity-calendar .rbc-ellipsis, .activity-calendar .rbc-event-label, .activity-calendar .rbc-row-segment .rbc-event-content, .activity-calendar .rbc-show-more{
	 font-weight: 700;
	 font-size: 15px;
}
.rbc-calendar{
	height: 90%;
}
@media (min-width: 1600px){
	    display: block;
	.activity-calendar .newPanel .panel-body .resultsLabelDate{
		font-size: 20px;
	}
	.activity-calendar .newPanel .panel-body .resultsLabelInfo{
		font-size: 18px;
	}
	.activity-calendar .newPanel .panel-body span.resultsLabel{
		font-size: 18px;
                margin-right: 14px;
	}
}
@media only screen and ( max-width: 1100px) {
	.activity-calendar .calendar-toolbar{
		display: block;
	}
	.activity-calendar .calendar .activeFilters{
		top: 68px;
	}
	.activity-calendar .calendar-toolbar .nextMonth{
	  display: none;
	}
}
@media only screen and ( max-width: 767px) {
	.activity-calendar .newPanel .panel-body .status-container{
		padding: 0px;
    margin-left: 15px;
	}
	.activity-calendar .moduleSubMenu .button-primary i{
		display: block;
	}
	.activity-calendar .calendar-toolbar .items{
		font-size: 14px;
	}
	.activity-calendar .calendar-toolbar .currentMonth h2 {
    margin-top: 30px;
  }
	.activity-calendar .dashboard .dropdown-menu {
	 margin-left: -80px;
	 position: fixed;
	 top: auto;
	 left: 56%;
	 bottom: 0px;
	 z-index: 999;
 }
 .activity-calendar .filters{
	 top: 38px;
	 z-index: 999;
 }
 .activity-calendar .newPanel .panel-body .row.m-bot15{
    margin-left: 0px;
    margin-right: 0px;
  }
	.activity-calendar .filter-type{
	margin-top: 30px;
	display: grid;
  }
  .activity-calendar .filter-type #eventsAll, .activity-calendar .filter-type #employeesAll{
	margin-left: 0px;
}
	.activity-calendar .calendar .activeFilters{
		top: 0px;
		max-width: 80%;
		position: relative;
		margin-left: 20px;
	}
	.rbc-month-view{
	  height: 85%;
	}
}

.modalS{
    background-color: #fff;
    border-radius: 5px;
    width: 60%;
    max-height: 90%;
    min-height: 300px;
    margin: 0 auto;
    padding-top: 30px;
    padding-left: 10px;
    padding-right: 10px;
    overflow-y: auto;
}
.modalS > div.text-right > div > i{
    color: lightgray;
}
.onboard {
    top: -15px!important;
    bottom: -25px!important;
}
 .onboarding {
    background: -webkit-linear-gradient(top, rgb(0, 220, 174) 0%, rgb(3, 183, 210) 100%);
    background: -o-linear-gradient(top, rgb(0, 220, 174) 0%, rgb(3, 183, 210) 100%);
    background: -ms-linear-gradient(top, rgb(0, 220, 174) 0%, rgb(3, 183, 210) 100%);
    background: -moz-linear-gradient(top, rgb(0, 220, 174) 0%, rgb(3, 183, 210) 100%);
    background: linear-gradient(to bottom, rgb(0, 220, 174) 0%, rgb(3, 183, 210) 100%);
    border-radius: 0px;
    padding-left: 0;
    padding-right: 0;
    overflow: visible;
    display: table;
}
.onboarding h2, .onboarding h4 {
    color: white;
}
.onboarding select {
    background-color: white;
    cursor: pointer;
}
.onboarding-selectable {
/*    margin-top: 20px;*/
}
.onboarding-modules{
    background-color: #fff;
    width: 100%;
    margin-top: 15px;
}
.onboarding-modules h4, h6 {
    color: black;
}
.onboarding-modules h6 {
    font-size: 14px;
}
.onboarding-modules-all {
    margin-top: 20px;
    margin-left: 20px;
}
#initial-tax, #initial-currency {
    width: 77%;
    min-width: 100px!important;
    height: 40px;
    border-radius: 4px;
    border: 1px solid rgb(166, 166, 166);
    float: right;
}
.onboarding-modules-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 8.5px 0 8.5px;
    border-color: #1BD0C0 transparent transparent transparent;
    margin: 0 auto;
}
.onboarding-line-module {
    width: 15%;
    display: inline-block;
    padding: 19px 9px;
    border: 2px solid lightgrey;
    color: lightgray;
    margin-right: 32px;
    margin-bottom: 14px;
    cursor: pointer;
}
.onboarding-line-module > p {
        margin: 0;
}
.onboarding-line-module span{
    font-size: 17px;
    position: absolute;
    left: 99px;
    color:  #44c699;
}
.onboarding-line-module.checked{
    color:  #44c699;
    border: 2px solid  #44c699;
    background-color: #f8f8f8;
}
.onboarding-line-module.checked:after{
    content:'✓';
    position: relative;
    float: right;
    margin-top: -52px;
    font-size: 22px;
}
.onboarding-demo {
    background-color: white;
    margin-top: 8px;
    border-radius: 6px;
    box-shadow: 2px 2px 9px #888888;
    padding: 20px;
}
.onboarding-demo p {
    margin-left: 30px;
    font-size: 16px;
    color: black;
    margin-top: 15px;
}

.onboarding-modules-all > div > label {
    margin-top: 11px;
}

.dot {
  height: 35px;
    width: 35px;
    background-color: white;
    border-radius: 50%;
    display: inline-block;
    margin-left: -2px;
    margin-right: -2px;
    padding: 5px;
    color: rgb(38, 213, 179);
    font-size: 20px;
    font-weight: bold;
}
.rectangle {
  height: 4px;
    width: 123px;
    background-color: white;
    display: inline-block;
    margin-bottom: 4px;
}
.links {
    color: #2a6496;
    font-weight: bold;
}
.links:hover {
    color: #2a6496;
}
.textarea-notif {
        color: black;
    }
.fa.fa-close.notif {
        color: black;
    }

.modalConfirm{
    background-color: #fff;
    border-radius: 5px;
    width: 40%;
    height: auto;
    min-height: 100px;
    margin: 0 auto;
    padding: 30px;
    overflow-y: scroll;
}

.read-more{
    display: block;
    margin-top: 6px;
    font-weight: bold;
}
.read-more a{
    color: #3E86B0;
}
.date_r {
    font-style: italic;
    margin-top: 15px;
    margin-left: -5px;
}
.date_r img{
    width: 20px;
    border-radius: 50%;
    height: 20px;
}
.react-datepicker__navigation--next {
    border-left-color: #ccc;
    right: 1em;
}
.backendropss {
    z-index: 1000!important;
    position: fixed;
    top: 50px;
    bottom: 0;
    left: 0;
    right: 0;
    padding-top: 40px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: rgba(0,0,0,0.4);
     width: 100%;
}
.posPage .backendropss{
    top: 0!important;
}
.newPanel{
    padding: 0;
    display: table;
    width: 100%;
    /*margin-top: 15px;*/
}
.button-outlines{
    border: 1px solid;
    border-radius: 3px;
    height: 35px;
    float: inherit;
    line-height: 35px;
    padding: 0 15px;
    text-align: center;
    margin: 15px;
    min-width: 100px;
    cursor: pointer;
    background: transparent;
}

.button-primary-edited {
    border: 1px solid #44c699;
    background: #44c699;
    color: #fff !important;
    border-radius: 3px;
    height: 35px;
    float: inherit;
    line-height: 35px;
    padding: 0 15px;
    text-align: center;
    margin: 15px;
    min-width: 125px;
    cursor: pointer;
}

.crmActivities{
    margin-top: 30px;
}
p.pull-left > sup {
    margin-left: 6px;
    font-size: 15px;
}
p.pull-left > sup >i{
    transform: rotate(-45deg);
}

.button-primary {
    font-family:  'ProximaNovaA',sans-serif;
    font-size: 14px;
}
.act-date{
    float: right;
    margin-right: 50px;
}
.type i.fa {
  display: inline-block;
  border-radius: 60px;
  box-shadow: 0px 0px 2px #EFEFEF;
  padding: 0.5em 0.6em;
  background: #EFEFEF;
  color: #ACAAAD;
}
.type {
       margin-left: -5px;
}

@media only screen and (max-width: 1152px) {
 .modalS{
    background-color: #fff;
    border-radius: 5px;
    width: 80%;
    height: 90%;
    max-height: 90%;
    min-height: 300px;
    margin: 0 auto;
    padding-top: 30px;
    overflow-y: auto;
}

.modalConfirm{
    background-color: #fff;
    border-radius: 5px;
    width: 100%;
    height: auto;
    min-height: 100px;
    margin: 0 auto;
    padding: 30px;
    overflow-y: scroll;
}

.button-outlines{
    border: 1px solid;
    border-radius: 3px;
    height: 35px;
    float: inherit;
    line-height: 35px;
    padding: 0 15px;
    text-align: center;
    margin: 15px;
    min-width: 50px;
    cursor: pointer;
    background: transparent;
}

.button-primary-edited {
    border: 1px solid #44c699;
    background: #44c699;
    color: #fff !important;
    border-radius: 3px;
    height: 35px;
    float: inherit;
    line-height: 35px;
    padding: 0 15px;
    text-align: center;
    margin: 15px;
    min-width: 50px;
    cursor: pointer;
}

.newPanel{
    padding: 0;
    display: table;
    width: 100%;
}
}

input[type="text"] {
    width: 100%;
}

textarea{
    min-width: 320px;
    min-height: 140px;
    resize: vertical;
    width: 100%;
}

.css-1rtrksz {
    padding: 0 7px!important;
}
.css-2o5izw:hover {
    border: 1px solid #44c699!important;
    box-shadow: 0 0 2px 0 rgba(68, 198, 153, 0.3)!important;
}

.css-2o5izw:focus, .css-2o5izw:not(:focus) {
    border: 1px solid #44c699!important;
    box-shadow: 0 0 2px 0 rgba(68, 198, 153, 0.3)!important;
}
.css-bl6clz{
    border: none!important;
    border-left: none!important;
    border-right: none!important;
}
.css-xwjg1b {
    text-transform: capitalize;
}

@media only screen and (max-width: 767px) {
    textarea{
        min-width: 180px;
        min-height: 140px;
        resize: vertical;
        width: 100%;
    }
    .type {
        font-size: 9px;
        line-height: 22px;
    }
    .backendropss {
        top: 93px;
        z-index: 2000!important;
    }
    .modalS {
        width: 100%;
        height: 90%;
        max-height: 90%;
        overflow-y: auto;
    }
    textarea {
        border: 1px solid grey;
    }
    .onboarding-line-module {
        width: 37%;
        padding: 19px 3px;
    }
    .rectangle{
        width: 70px;
    }
    #initial-tax, #initial-currency {
        float: none;
        width: 80%;
    }
}
.p_wrap {
    white-space: pre-line;
    word-wrap: break-word;
    font-size: 16px;
  }

.react-datepicker-ignore-onclickoutside{
    width: 100%;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
    margin-left: -8px;
    position: absolute;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
    box-sizing: content-box;
    position: absolute;
    border: 8px solid transparent;
    height: 0;
    width: 1px;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
    content: "";
    z-index: -1;
    border-width: 8px;
    left: -8px;
    border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
    top: 0;
    margin-top: -8px;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
    border-top: none;
    border-bottom-color: #f0f0f0;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
    top: -1px;
    border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
    bottom: 0;
    margin-bottom: -8px;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
    border-bottom: none;
    border-top-color: #fff;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
    bottom: -1px;
    border-top-color: #aeaeae;
}

.react-datepicker-wrapper {
    display: inline-block;
    width: 100%;
}

.react-datepicker {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 1em;
    background-color: #fff;
    color: #000;
    border: 1px solid #aeaeae;
    border-radius: 0.3rem;
    display: inline-block;
    position: relative;
}

.react-datepicker--time-only .react-datepicker__triangle {
    left: 35px;
}

.react-datepicker--time-only .react-datepicker__time-container {
    border-left: 0;
}

.react-datepicker--time-only .react-datepicker__time {
    border-radius: 0.3rem;
}

.react-datepicker--time-only .react-datepicker__time-box {
    border-radius: 0.3rem;
}

.react-datepicker__triangle {
    position: absolute;
    left: 50px;
}

.react-datepicker-popper {
    z-index: 1;
}

.react-datepicker-popper[data-placement^="bottom"] {
    margin-top: 10px;
}

.react-datepicker-popper[data-placement^="top"] {
    margin-bottom: 10px;
}

.react-datepicker-popper[data-placement^="right"] {
    margin-left: 8px;
}

.react-datepicker-popper[data-placement^="right"] .react-datepicker__triangle {
    left: auto;
    right: 42px;
}

.react-datepicker-popper[data-placement^="left"] {
    margin-right: 8px;
}

.react-datepicker-popper[data-placement^="left"] .react-datepicker__triangle {
    left: 42px;
    right: auto;
}

.react-datepicker__header {
    text-align: center;
    background-color: #f0f0f0;
    border-bottom: 1px solid #aeaeae;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    padding-top: 0.8em;
    position: relative;
}

.react-datepicker__header--time {
    padding-bottom: 8px;
    padding-left: 5px;
    padding-right: 5px;
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
    display: inline-block;
    margin: 0 2px;
}

.react-datepicker__current-month,
.react-datepicker-time__header {
    margin-top: 0;
    color: #000;
    font-weight: bold;
    font-size: 1em;
}

.react-datepicker-time__header {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.react-datepicker__navigation {
    background: none;
    text-align: center;
    cursor: pointer;
    position: absolute;
    width: 0;
    padding: 0;
    z-index: 1;
    top: 1em;
    line-height: 1.7em;
    border: 0.45em solid transparent;
}

.react-datepicker__navigation--previous {
    border-right-color: #ccc;
    left: 1em;
}

.react-datepicker__navigation--previous:hover {
    border-right-color: #b3b3b3;
}

.react-datepicker__navigation--previous--disabled, .react-datepicker__navigation--previous--disabled:hover {
    border-right-color: #e6e6e6;
    cursor: default;
}

.react-datepicker__navigation--next {
    border-left-color: #ccc;
    right: 1em;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
    right: 80px;
}

.react-datepicker__navigation--next:hover {
    border-left-color: #b3b3b3;
}

.react-datepicker__navigation--next--disabled, .react-datepicker__navigation--next--disabled:hover {
    border-left-color: #e6e6e6;
    cursor: default;
}

.react-datepicker__navigation--years {
    position: relative;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.react-datepicker__navigation--years-previous {
    top: 4px;
    border-top-color: #ccc;
}

.react-datepicker__navigation--years-previous:hover {
    border-top-color: #b3b3b3;
}

.react-datepicker__navigation--years-upcoming {
    top: -4px;
    border-bottom-color: #ccc;
}

.react-datepicker__navigation--years-upcoming:hover {
    border-bottom-color: #b3b3b3;
}

.react-datepicker__month-container {
    float: left;
}

.react-datepicker__month {
    margin: 0.4em 1em;
    text-align: center;
}

.react-datepicker__time-container {
    float: right;
    border-left: 1px solid #aeaeae;
    width: 70px;
}

.react-datepicker__time-container--with-today-button {
    display: inline;
    border: 1px solid #aeaeae;
    border-radius: 0.3rem;
    position: absolute;
    right: -72px;
    top: 0;
}

.react-datepicker__time-container .react-datepicker__time {
    position: relative;
    background: white;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    width: 70px;
    overflow-x: hidden;
    margin: 0 auto;
    text-align: center;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
    list-style: none;
    margin: 0;
    height: calc(195px + (1.7rem / 2));
    overflow-y: scroll;
    padding-right: 30px;
    width: 100%;
    box-sizing: content-box;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
    padding: 5px 10px;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
    cursor: pointer;
    background-color: #f0f0f0;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
    background-color: #216ba5;
    color: white;
    font-weight: bold;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
    background-color: #216ba5;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
    color: #ccc;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover {
    cursor: default;
    background-color: transparent;
}

.react-datepicker__week-number {
    color: #ccc;
    display: inline-block;
    width: 1.7rem;
    line-height: 1.7rem;
    text-align: center;
    margin: 0.166rem;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable {
    cursor: pointer;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
    border-radius: 0.3rem;
    background-color: #f0f0f0;
}

.react-datepicker__day-names,
.react-datepicker__week {
    white-space: nowrap;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
    color: #000;
    display: inline-block;
    text-align: center;
    width: 1.9em;
    line-height: 1.9em;
    margin: 0.166em;
}

.react-datepicker__day {
    cursor: pointer;
}

.react-datepicker__day:hover {
    border-radius: 0.3rem;
    background-color: #f0f0f0;
}

.react-datepicker__day--today {
    font-weight: bold;
}

.react-datepicker__day--highlighted {
    border-radius: 0.3rem;
    background-color: #3dcc4a;
    color: #fff;
}

.react-datepicker__day--highlighted:hover {
    background-color: #32be3f;
}

.react-datepicker__day--highlighted-custom-1 {
    color: magenta;
}

.react-datepicker__day--highlighted-custom-2 {
    color: green;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range {
    border-radius: 0.3rem;
    background-color: #216ba5;
    color: #fff;
}

.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover {
    background-color: #1d5d90;
}

.react-datepicker__day--keyboard-selected {
    border-radius: 0.3rem;
    background-color: #2a87d0;
    color: #fff;
}

.react-datepicker__day--keyboard-selected:hover {
    background-color: #1d5d90;
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range) {
    background-color: rgba(33, 107, 165, 0.5);
}

.react-datepicker__month--selecting-range .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range) {
    background-color: #f0f0f0;
    color: #000;
}

.react-datepicker__day--disabled {
    cursor: default;
    color: #ccc;
}

.react-datepicker__day--disabled:hover {
    background-color: transparent;
}

.react-datepicker__input-container {
    position: relative;
    display: inline-block;
    width: 100%;
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
    border: 1px solid transparent;
    border-radius: 0.3rem;
}

.react-datepicker__year-read-view:hover,
.react-datepicker__month-read-view:hover,
.react-datepicker__month-year-read-view:hover {
    cursor: pointer;
}

.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
    border-top-color: #b3b3b3;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
    border-top-color: #ccc;
    float: right;
    margin-left: 20px;
    top: 8px;
    position: relative;
    border-width: 0.45rem;
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
    background-color: #f0f0f0;
    position: absolute;
    width: 50%;
    left: 25%;
    top: 30px;
    z-index: 1;
    text-align: center;
    border-radius: 0.3rem;
    border: 1px solid #aeaeae;
}

.react-datepicker__year-dropdown:hover,
.react-datepicker__month-dropdown:hover,
.react-datepicker__month-year-dropdown:hover {
    cursor: pointer;
}

.react-datepicker__year-dropdown--scrollable,
.react-datepicker__month-dropdown--scrollable,
.react-datepicker__month-year-dropdown--scrollable {
    height: 150px;
    overflow-y: scroll;
}

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
    line-height: 20px;
    width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.react-datepicker__year-option:first-of-type,
.react-datepicker__month-option:first-of-type,
.react-datepicker__month-year-option:first-of-type {
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
}

.react-datepicker__year-option:last-of-type,
.react-datepicker__month-option:last-of-type,
.react-datepicker__month-year-option:last-of-type {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-bottom-left-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
}

.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover {
    background-color: #ccc;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
    border-bottom-color: #b3b3b3;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
    border-top-color: #b3b3b3;
}

.react-datepicker__year-option--selected,
.react-datepicker__month-option--selected,
.react-datepicker__month-year-option--selected {
    position: absolute;
    left: 15px;
}

.react-datepicker__close-icon {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    display: inline-block;
    height: 0;
    outline: 0;
    padding: 0;
    vertical-align: middle;
}

.react-datepicker__close-icon::after {
    background-color: #216ba5;
    border-radius: 50%;
    bottom: 0;
    box-sizing: border-box;
    color: #fff;
    content: "\00d7";
    cursor: pointer;
    font-size: 12px;
    height: 16px;
    width: 16px;
    line-height: 1;
    margin: -8px auto 0;
    padding: 2px;
    position: absolute;
    right: 7px;
    text-align: center;
    top: 50%;
}

.react-datepicker__today-button {
    background: #f0f0f0;
    border-top: 1px solid #aeaeae;
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    padding: 5px 0;
    clear: left;
}

.react-datepicker__portal {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    left: 0;
    top: 0;
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 2147483647;
}

.react-datepicker__portal .react-datepicker__day-name,
.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__time-name {
    width: 3rem;
    line-height: 3rem;
}

@media (max-width: 400px), (max-height: 550px) {
    .react-datepicker__portal .react-datepicker__day-name,
    .react-datepicker__portal .react-datepicker__day,
    .react-datepicker__portal .react-datepicker__time-name {
        width: 2rem;
        line-height: 2rem;
    }
}

.react-datepicker__portal .react-datepicker__current-month,
.react-datepicker__portal .react-datepicker-time__header {
    font-size: 1.44rem;
}

.react-datepicker__portal .react-datepicker__navigation {
    border: 0.81rem solid transparent;
}

.react-datepicker__portal .react-datepicker__navigation--previous {
    border-right-color: #ccc;
}

.react-datepicker__portal .react-datepicker__navigation--previous:hover {
    border-right-color: #b3b3b3;
}

.react-datepicker__portal .react-datepicker__navigation--previous--disabled, .react-datepicker__portal .react-datepicker__navigation--previous--disabled:hover {
    border-right-color: #e6e6e6;
    cursor: default;
}

.react-datepicker__portal .react-datepicker__navigation--next {
    border-left-color: #ccc;
}

.react-datepicker__portal .react-datepicker__navigation--next:hover {
    border-left-color: #b3b3b3;
}

.react-datepicker__portal .react-datepicker__navigation--next--disabled, .react-datepicker__portal .react-datepicker__navigation--next--disabled:hover {
    border-left-color: #e6e6e6;
    cursor: default;
}

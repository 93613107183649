.scan-spinner{
    position: absolute;
    display: flex;
    width: 100%;
    padding: 15px;
    height: 90%;
    background-color: #fbfafa;
    opacity: 0.94;
}

.rounded-button-primary {
    min-width: 56px;
    width: 56px;
    height: 56px;
    position: fixed;
    bottom: 10px;
    right: 20px;
    z-index: 99;
    border-radius: 50%;
    font-size: 25px;
    border: 1px solid #44c699;
    background: #44c699 !important;
    color: #fff !important;
    float: left;
    line-height: 35px;
    padding: 0 15px;
    text-align: center;
    margin: 15px;
    cursor: pointer;
}
.dataTable input[type="text"],
.dataTable input[type="number"],
.dataTable .css-1aya2g8{
	margin-bottom: 0;
	border-radius: 0;
	border: none;
}

.dataTable td{
	border: 1px solid #ccc;
}

.dataTable thead {
    border: 1px solid #ccc;
    background-color: #eaf0f5;
}

.dataTable thead td {
    border: 0;
    padding: 10px 3px 0 3px !important;
    font-weight: bold;
}

.json-builder-table {
    border-collapse: collapse;
    width: 100%;
}

.json-builder-table > tr, .json-builder-table > tr > th, .json-builder-table > tr > td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 24-Apr-2019, 10:11:58
    Author     : neva
*/

input.custom-text {
    background: white;
    height: 100px;
    line-height: 25px;
    margin-top: 10px;
    box-shadow: 0 1px 1px #aaa;
    margin: 30px 0 0 0;
    border-color: #d3d3d3;
}

button[disabled].button-issue {
    background-color: rgba(0,255,0,0.3)!important;
    border: 1px solid rgba(0,255,0,0.3);
}

.form-contacts {
    min-width: unset;
    width: 40%;
    height: 40px;
    margin-right: 10px;
    color: black;
}

.form-stage{
    min-width: unset;
    width: 100%;
    height: 40px;
    color: black;
    margin: 0 !important;
    border-radius: 0;
    background-color: #fff;
    border-radius: 4px;
}
.stages table tbody td{
    padding: 0px 10px;
}
.form-stage.form-stage-select{
    min-width: 120px;
    border: 1px solid #dbdadb!important;
  }
.contact-icon {
    float: right;
    margin-top: -27px;
    margin-right: -10px;
    cursor: pointer;
}

.add-translations {
    position: absolute;
    left: 48%;
    top: 140px;
}

 i.fa.fa-language {
    cursor: pointer;
}

.sub-document {
     background: #F4F4F4;
}

.main-document {
    font-weight: 600;
}

.panel .panel-body .mobileSelect{
    padding-left: 0px;
}

.set-in-place {
    position: absolute;
    top: 9px;
    left: 100px;
}

.bom-set-in-place {
    position: absolute;
    top: 3px;
    right: 100px;
}

.set-in-place-make-payment {
    position: relative;
    top: 30px;
}
.pay_total .form-group{
    margin-top: 20px;
    margin-bottom: 20px;
    border: 0;
    border-top: 1px solid #eee;
}
.pay_total .panel.total {
    border: none;
    box-shadow: none;
    min-height: auto;
  }
.disabled-breadcrumb {
    pointer-events: none;
    opacity: 0.5;
    cursor: not-allowed;
}

.simple-form-field {
    font-size: 20px;
}

.ant-pagination {
    text-align: center;
    margin-bottom: 10px;
}

.form-input-field .ant-select-selection--single {
    height: 40px;
    border: none;
    border-radius: 0;
}

.form-input-field .ant-select-enabled {
    width: 100%;
}

.help {
    position: relative;
    top: -7px;
    left: 3px;
}
.ant-calendar-picker-input.ant-input {
    height: 40px;
    margin-bottom: 15px;
}

.multipleSelectList .css-xwjg1b {
    display: none;
}

.collapsible {
    background-color: white;
    color: black;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    border: 1px solid lightgrey;
    text-align: left;
    outline: none;
    font-size: 15px;
    border-radius: 4px;
}

.active, .collapsible:hover {
  background-color: #f1f1f1;
}

.ant-switch-checked{
    background-color: #38b88c!important;
}

.searchItem-table tbody td input[type="number"]{
    min-width: 100px!important;
}

/*.content {
    padding: 35px;
    overflow: hidden;
    background-color: white;
    border: 1px solid lightgrey;
}
*/

.ant-card-extra {
    cursor: pointer;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    /* height: 40px; */
    border-color: #40a9ff;
    border-right-width: 1px !important;
}

.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
    height: 40px;
}

.ant-form > .ant-select-selector {
    height: 40px!important;
}

.account-profile-file-upload .ant-select-selector {
    height: 42px!important;
  }

select.form-control {
    border: 1px solid #d6d6d6;
    height: 40px;
    padding: 0 5px;
    float: left;
    color: #444;
    font-weight: 300;
    font-size: 16px;
    border-radius: 3px;
    background: transparent;
    min-width: 120px; 
    padding: 10px 0;
    margin-bottom: 15px;
    letter-spacing: .5px;
    width: 100%;
}

.newPanel .panel-body .row #reactForm {
background: white;
}

.m-right {
    margin-left: 30px;
}

.h-field {
    height: 40px !important;
    margin-bottom: 50px;
    max-height: 40px;
}

